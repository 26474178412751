<template>
  <div class="flex flex-center placeholder"></div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.placeholder {
  background: #f4f4f4;
  position: relative;
  height: 100%;
  width: 100%;
}

.placeholder:before {
  background: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1021_12093)'%3E%3Cpath d='M40.0007 50.6666C45.8917 50.6666 50.6673 45.891 50.6673 40C50.6673 34.1089 45.8917 29.3333 40.0007 29.3333C34.1096 29.3333 29.334 34.1089 29.334 40C29.334 45.891 34.1096 50.6666 40.0007 50.6666Z' fill='%239F9F9F'/%3E%3Cpath d='M29.9993 6.66669L23.8993 13.3334H13.3327C9.66602 13.3334 6.66602 16.3334 6.66602 20V60C6.66602 63.6667 9.66602 66.6667 13.3327 66.6667H66.666C70.3327 66.6667 73.3327 63.6667 73.3327 60V20C73.3327 16.3334 70.3327 13.3334 66.666 13.3334H56.0993L49.9993 6.66669H29.9993ZM39.9993 56.6667C30.7993 56.6667 23.3327 49.2 23.3327 40C23.3327 30.8 30.7993 23.3334 39.9993 23.3334C49.1993 23.3334 56.666 30.8 56.666 40C56.666 49.2 49.1993 56.6667 39.9993 56.6667Z' fill='%239F9F9F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1021_12093'%3E%3Crect width='80' height='80' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
    center center / cover;
  content: '';
  display: block;
  height: 25px;
  width: 22px;
}
</style>
