import { CopyWithPartial, registerEntityEndpoint } from '@editor/ui-utils';
import { IImageDocument, StrictPartialImageDocument } from '@modules/image-data-schema';

export const ImageModelName = 'Images';
export const ImageService = registerEntityEndpoint<IImageDocument>(ImageModelName);
const { getEntity, listEntities, postEntity, putEntity, deleteEntity, getEntityAPIClient } = ImageService;

export const getImages = async (search = null): Promise<{ list: IImageDocument[] }> => {
  return listEntities<{ list: IImageDocument[] }>({ params: { search: search || null } });
};

interface IImageDetail {
  image: IImageDocument;
  squarePreview?: string;
  widePreview?: string;
}

const commonExcludedFields = {
  createDate: undefined,
  changeDate: undefined,
  history: undefined,
  source: undefined,
};

export const getImage = async (id: string, includePreview = false): Promise<IImageDetail> => {
  return getEntity<IImageDetail>(id, { params: { includePreview } });
};

// TODO: Move image size const values to a place that handles it
export const getImageUrl = (uuid: string, width = 640, height = 360): string => {
  return `${getEntityAPIClient().defaults.baseURL}/${uuid}/url/${width}/${height}`;
};

export const createImage = async (data: StrictPartialImageDocument): Promise<IImageDetail> => {
  const sanitizedData = { ...data, _id: undefined, ...commonExcludedFields };
  return postEntity(sanitizedData);
};

export const updateImage = async (id: string, data: StrictPartialImageDocument): Promise<IImageDetail> => {
  const sanitizedData = { ...data, ...commonExcludedFields };
  return putEntity(id, sanitizedData);
};

export const uploadImage = async (image: StrictPartialImageDocument, fileBlob: File): Promise<IImageDetail> => {
  const sanitizedImage = { ...image, _id: undefined, ...commonExcludedFields };

  const formData = new FormData();
  formData.append('image', JSON.stringify(sanitizedImage));
  formData.append('file', fileBlob);

  return postEntity(formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const replaceImage = async (image: CopyWithPartial<IImageDocument, '_id'>, fileBlob: File): Promise<IImageDetail> => {
  const sanitizedImage = { ...image, ...commonExcludedFields };

  const formData = new FormData();
  formData.append('image', JSON.stringify(sanitizedImage));
  formData.append('file', fileBlob);

  return putEntity(`${image._id}/replace`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const editImage = async (image: StrictPartialImageDocument): Promise<IImageDetail> => {
  const sanitizedImage = { ...image, ...commonExcludedFields };
  return putEntity(image._id || '', sanitizedImage);
};

export const deleteImage = async (image: StrictPartialImageDocument): Promise<string> => {
  return deleteEntity(image._id);
};
